import React from 'react'
import fetch from 'isomorphic-unfetch'
import PropTypes from 'prop-types'
import PageFront from 'components/PageFront'
import PageRegular from 'components/PageRegular'
import PageContact from 'components/PageContact'
import apiUrl from 'utils/apiUrl'
import { getSlugString } from 'utils/utils'

class Page extends React.Component {
  static async getInitialProps ({ context, store, isServer, pathname, query, req }) {
    const slug = query.slug ? query.slug : pathname
    const page = '/' + getSlugString(slug)
    try {
      if (page === '/') {
        const displayPage = store.getState().user.hasAccess ? 'forside-innlogget' : 'forside'
        const res = await fetch(`${apiUrl}/content/page?slug=${displayPage}`)
        const searchResult = await res.json()
        if (searchResult.length === 0) {
          return { statusCode: 404 }
        }
        return {
          page: searchResult[0], path: pathname
        }
      } else {
        const res = await fetch(`${apiUrl}/content/page?slug=${page}`)
        const searchResult = await res.json()
        if (searchResult.length === 0) {
          return { statusCode: 404 }
        }
        return {
          page: searchResult[0],
          path: pathname,
          releaseNo: searchResult.releaseNo
        }
      }
    } catch (e) {
      return {
        error: 'Det oppstod en uforutsett feil. Kontakt systemansvarlig'
      }
    }
  }

  render () {
    const { statusCode, error, page } = this.props
    return (
      <div>
        <div className='container'>
          {page && page.template && page.template === 'frontpage.php' && <PageFront page={page} />}
          {page && page.template && page.template === 'contact.php' && <PageContact page={page} />}
          {page && page.template === '' && <PageRegular page={page} />}
          {statusCode && <h1>{statusCode}</h1>}
          {error}
        </div>
        <style>{`
          h1, h2, h3 {
            text-align: center;
          }
          .cms-content ul {
            margin-left: 1.3rem;
          }
          div.littlist div.item {
            margin-bottom: 1rem;
          }
          .page-container {
            width: 100%;
            max-width: 800px;
            padding: 0 1rem;
            margin: 0 auto;
          }
        `}
        </style>
      </div>
    )
  }
}

Page.propTypes = {
  error: PropTypes.string,
  page: PropTypes.object,
  statusCode: PropTypes.number
}

export default Page
